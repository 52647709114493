<template>
  <v-container class="fill-height pt-0" fluid>
    <v-row class="d-flex flex-row align-center py-1">
      <div class="text-left black--text px-3" style="margin-top:3%">
        {{$t('Organizations')}}
      </div>
      <v-spacer></v-spacer>
      <v-col cols="3" md="2" class="text-right px-0 mt-1">
      </v-col>
      <v-col cols="9" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-divider></v-divider>
    </v-row>

    <v-row>
      <v-col cols="12" md="9"> </v-col>
      <v-col cols="12" md="3" class="text-right">
        <v-btn color="primary" text  @click.prevent="openDialog()">
          <v-icon left color="primary">mdi-plus-circle-outline</v-icon>{{$t('add_organization')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="getEntities"
          class="elevation-1"
          dense
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="blue"
              x-small
              fab
              class="ma-1"
              dark
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
            <!-- <v-btn
              color="primary"
              x-small
              fab
              class="ma-1"
              dark
              @click="openEditDialog(item)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn> -->
            <v-btn
              color="error"
              x-small
              fab
              class="ma-1"
              dark
              @click="openConfirmeDialog(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <add :dialog="dialog" @close="closeAddDialog()" />
    <edit-dialog v-if="editDialog" :dialog="editDialog" :propEntity="entityToEdit" @close="closeEditDialog()" />

    <confirm-dialog
      :dialog="confirm"
      :id="removeId"
      :question="$t('organizationQ')"
      @yes="deleteEntity"
      @no="confirm = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />

  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatError } from "@/utils";
import { ENTITIES_QUERY } from './../graphql/Query';
import { DELETE_ENTITY_MUTATION } from './../graphql/Mutation';
import Add from './../components/AddEntityDialog';
import EditDialog from './../components/EditEntityDialog';
import dialogMixins from '@/mixins/dialog'
import ConfirmDialog from './../../../components/ConfirmDialog.vue'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'

export default {
  name: 'Organization',
  mixins: [
    dialogMixins
  ],
  components: {
    Add,
    EditDialog,
    ConfirmDialog, ErrorDialog, ProgressDialog, SuccessDialog
  },
  data: () => ({
    entities: [],
    dialog: false,
    editDialog: false,
    entityToEdit: null
  }),
  apollo: {
    entities: {
      query: ENTITIES_QUERY
    }
  },
  watch: {
    entities: function (val) {
      this.setEntities(val)
    }
  },
  computed: {
    ...mapGetters({
      getEntities: 'entity/getEntities'
    }),
    headers () {
      return [
        {
          text: this.$t('name'),
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t('email'),
          align: "start",
          sortable: false,
          value: "email"
        },
        {
          text: this.$t('telephone'),
          align: "start",
          sortable: false,
          value: "telephone"
        },
        
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status"
        },
        { text: this.$t('actions'), align: "center", value: "actions" }
      ];
    }
  },
  methods: {
    ...mapActions({
      removeEntity: 'entity/deleteEntity',
      setEntities: 'entity/setEntities',
    }),
    openDialog () {
      this.dialog = true;
    },
    openEditDialog (entity) {
      this.entityToEdit = entity;
      this.editDialog = true;
    },
    closeAddDialog() {
      this.dialog = false;
    },
    closeEditDialog() {
      this.editDialog = false;
    },
    openConfirmeDialog (id) {
      this.removeId = id
      this.confirm = true
    },
    async deleteEntity (id) {
      this.confirm = false
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: DELETE_ENTITY_MUTATION,
          variables: {
            id
          }
        })
        this.removeEntity(id)
        this.success = this.$t('organisation_removed_successfully')
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>