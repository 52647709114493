<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card class="pb-5">
        <v-card-title class="justify-center text-uppercase"
          >{{ $t('add_organization') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" class="pb-0">
              <v-text-field
                v-model="entity.name"
                :label="$t('name')"
                dense
                prepend-inner-icon="mdi-domain"
                outlined
                required
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 pb-0">
              <v-text-field
                v-model.trim="entity.email"
                label="Email"
                dense
                prepend-inner-icon="mdi-email"
                outlined
                required
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0 pb-0">
              <v-text-field
                v-model.trim="entity.telephone"
                :label="$t('form_user_phone')"
                dense
                prepend-inner-icon="mdi-phone"
                outlined
                required
                type="number"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
            <v-btn
              :color="'primary'"
              text
              class="mx-2"
              @click="$emit('close')"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              :disabled="isLoading"
              color="primary"
              @click="save()"
              >{{ $t("save") }}</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
  
      <error-dialog :dialog="showError" :message="error" @close="showError = false" />
      <progress-dialog :processing="isLoading" />
      <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
    </v-dialog>
  </template>
  
  <script>
  import { API_URL } from "@/api";
  import { CREATE_ORGANIZATION_MUTATION } from "./../graphql/Mutation";
  import { formatError } from "@/utils";
  import { mapActions } from 'vuex'
  import ErrorDialog from './../../../components/ErrorDialog.vue'
  import ProgressDialog from './../../../components/ProgressDialog.vue'
  import SuccessDialog from './../../../components/SuccessDialog.vue'
  
  export default {
    name: "AddEntityDialog",
    components: { ErrorDialog, ProgressDialog, SuccessDialog },
    props: {
      dialog: Boolean,
      propEntity: Object,
    },
    data: () => ({
      apiUrl: API_URL,
      croppa: {},
      errrValid: false,
      repeatPassword: "",
      entity: {
        name: '',
        email: '',
        telephone: '',
        username: '',
        password: '',
      },
      valid: true,
      isLoading: false,
      showError: false,
      error: undefined,
      showSuccess: false,
      success: undefined
    }),
    computed: {
      // ...mapGetters({
      //   currentUser: "auth/getCurrentUser",
      // }),
      repeatPasswordRules() {
        return [
          (v) => v === this.entity.password || this.$t("form_user_valid_check"),
        ];
      },
      emailRules() {
        return [
          (v) => !!v || this.$t("form_user_valid"),
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ];
      },
      requiredRules() {
        return [(v) => !!v || this.$t("form_user_valid")];
      },
    },
    methods: {
      ...mapActions({
        addEntity: 'entity/addEntity',
        updateRoleState: 'role/updateRole'
      }),
      async save () {
        this.isLoading = true
        try {
          const { data } = await this.$apollo.mutate({
            mutation: CREATE_ORGANIZATION_MUTATION,
            variables: this.entity,
          })
          this.addEntity(data.createOrganization)
          this.success = this.$t('organisation_added_successfully');
          this.showSuccess = true
          this.$emit('close')
        } catch (error) {
          this.error = formatError(error.message)
          this.showError = true
        } finally {
          this.isLoading = false
        }
      },
    },
    mounted() {
      this.entity = {
        name: this.propEntity.name,
        email: this.propEntity.email,
        telephone: this.propEntity.telephone,
        username: this.propEntity.username,
      }
    }
  }
  </script>